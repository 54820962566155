.logo {
    position: absolute;
    top: -4vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 99;
    width: 80%;
}

.lb-menu-bars-container {
    height: 14vw;
}

.lb-menu-search-container {
    height: 14vw;
}

.lb-menu-bars {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    height: 50%;
    cursor: pointer;
}

.lb-nav {
    background-image: url('/img/menubg.svg');
    background-position: center calc(100% + 0.85em);
    background-size: 110%;
    background-repeat: no-repeat;
    height: 14vw;
}

.search input {
    background: none;
    border-radius: 0;
    border: 0;
    border-bottom: 3px solid white;
    color: white;
}

.search button {
    background: none;
    border: none;
}

.lb-menu-search {
    height: 50%;
    position: absolute;
    top: 45%;
    left: 55%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.lb-menu {
    background-color: #417521;
}

.lb-menu ul {
    list-style: none;
}

.lb-menu ul li {
    padding-top: 20px;
}

.lb-menu ul a {
    color: white;
    text-decoration: none;
    font-size: 7vw;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}


.lb-menu-search i {
    font-size: 3vw;
    color: white;
}

.footer-icon {
    width: 100%;
    height: 4vh;
}

.footer-icon img {
    height: 100%;
}

.lb-sub-footer {
    background-image: url('/img/footer-sub-bg.svg');
    background-position: center 0;
    background-size: 110%;
    margin-bottom: -10px;
    padding-bottom: 10px;
}

.lb-footer {
    background-image: url('/img/footer-bg.svg');
    background-position: center 0;
    background-size: 110%;
    background-repeat: no-repeat;
    z-index: 99;
}


.lb-footer .col-3:not(.last-div) {
    border-right: 2px solid #417521;
    /*width: 75%;*/
}

.lb-footer p {
    color: #417521;
}

.lb-footer .footer-text {
    position: relative;
    height: 6vh;
}

.lb-footer .footer-text p {
    position: absolute;
    bottom: 0;
    padding: 0;
    margin: 0;
    font-size: 1.5vh;
    /*top: 0;*/
    left: 50%;
    transform: translate(-50%, 0%);
}

main {
    margin-top: -20px;
}

body {
    /*overflow-x: hidden;*/
}

.bottom-compensation {
    height: 15vh;
}

#map {
    width: 100%;
    height: 100vh;
}

#mapBlog {
    width: 100%;
    height: 40vh;
}

.leaflet-control-zoom {
    display: none;
}

.breadcrumbs {
    /*background-image: url('/img/breadcrumbg.svg');*/
    background-color: #77C24B;
    background-position: center 0;
    background-size: 110% auto;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    color: white;
    padding: 2px !important;
    margin-bottom: 10px;
    /*padding-bottom: 10px;*/
}

.breadcrumbs a {
    color: white;
    text-decoration: none;
}


.container h1, .container h2, .container h3, .container h4, .container h4, .container p {
    color: #29421A;
}

.btn-lb {
    color: #29421A;
    border: 2px solid #29421A;
}

.btn-lb:hover {
    color: #29421A;
    border: 2px solid #29421A;
}


.blog-item img {
    border-radius: 15px;
}

.blog-item:nth-child(odd) {
    background-color: #F1F8ED;
}


.leaflet-marker-pane img {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));
}

.leaflet-popup-content p {
    padding: 0 !important;
    margin: 0 !important;
    color: #29421A;
}

.leaflet-popup-content a {
    color: #FB8B2C !important;
    font-weight: 600;
}

.btn-oragne {
    background-color: #FB8B2C;
    border: 0;
    color: white;
}

.lb-dropup, .lb-dropup:focus, .lb-dropup:hover, .lb-dropup:active {
    text-align: left;
    background-color: #E3F2DB !important;
    border: 1px solid #29421A !important;
    color: #29421A !important;
    border-radius: 10px !important;
}

.lb-dropup img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 0;
    padding-right: 10px;
}

.dropdown-item .btn {
    margin-left: auto;
}

.lb-dropdown {
    background-color: #E3F2DB !important;
    border: 1px solid #29421A !important;
    color: #29421A !important;
    border-radius: 10px !important;
    padding: 0;
}

.lb-dropdown button {
    padding: 0;
    color: #29421A !important;
    text-decoration: none;
}

.lb-dropdown img {
    height: 75px;
    width: 75px;
}

.oragne-link {
    color: #FB8B2C !important;
    font-weight: 600;
}

.bg-light {
    background: #F1F8ED;
}

.info img {
    height: 4vh;
}

.shopMap {
    height: 20vh !important;
}

.leaflet-bottom.leaflet-right {
    display: none;
}

.accordion-button:not(.collapsed), .accordion-button:active {
    background: none;
    box-shadow: none;
    border: none;
}

.accordion-button {
    background: none;
}

.accordion-item {
    border: 0;
}

.accordion-item:nth-child(odd) {
    background-color: #F1F8ED;
}


.phone {
    position: relative;
    width: 400px;
    height: 90vh;
    background-color: #000;
    border-radius: 20px;
    overflow: hidden;
}

.screen {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 360px;
    height: 80vh;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

#filter {
    color: #29421A;
    text-decoration: none;
}

.amount {
    position: absolute;
    bottom: -10px;
    left: calc(50%);
    font-weight: bolder;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #FB8B2C;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-text {
    font-size: 4vw;
}

.dropdown-item:hover {
    border-radius: 10px;
}

.lb-dropdown img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

/*.blog-container {*/
/*    background-image: url('/img/footer-bg.svg');*/
/*    background-position: center 0;*/
/*    background-size: 110%;*/
/*    margin-top: -50px;*/
/*    z-index: 5;*/
/*}*/
